import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAndStoreUserDetails } from '../../controller/registerController';
import {  getUserDetailsFromLocalStorage } from '../../localStorageComp/storage';
import PersonIcon from '@mui/icons-material/Person';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import MenuIcon from '@mui/icons-material/Menu';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import SecurityIcon from '@mui/icons-material/Security';
import { useData } from "../../context/dataContext";
import DarkMode from '../../utils/color/DarkMode';
import {useDispatch, useSelector } from 'react-redux';
import {AppDispatch, RootState } from '../../store';
import { fetchUserDetails, userDetailsActions } from "../../store/userDetails";

const useStyles = makeStyles((theme) => ({
  iconsLineParent: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    cursor: 'pointer',
  },
  selectedCategory: {
    fontWeight: 'bold',
  },
  categories: {
    position: 'absolute',
    height: 300,
    width: 250,
    top: '13.35%',
    right: '78.48%',
    bottom: '50%',
    left: '2.78%',
    borderRadius: 16,
    // backgroundColor: '#fcfcfd',
    boxShadow: '0px 64px 64px -48px rgba(15, 15, 15, 0.08)',
    border: '1px solid #f4f5f6',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '48px 32px',
    gap: 40,
    textAlign: 'left',
    fontSize: 14,
    color: '#8dc8fd',
    zIndex: 10,
  },
}));

interface CategoryMenuProps {
  selectedCategory: string;
  open?: boolean;
}

const CategoryMenu: React.FC<CategoryMenuProps> = ({ open, selectedCategory: initialSelectedCategory }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState<any>(null);
  const [selectedCategory, setSelectedCategory] = useState(initialSelectedCategory);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const userFromStore = useSelector((store : RootState)=>store.user);
  const dispatch = useDispatch<AppDispatch>();
  const handleCategoryClick = (category: string, path: string) => {
    setSelectedCategory(category);
    navigate(path);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleMenuItemClick = (url: string) => {
    window.open(url, '_blank'); // Open link in a new tab
    handleMenuClose();
  };
  const { globalData } = useData();
  const userDetailsFromStore = useSelector((store: RootState) => store.userDetails);
{/*
    useEffect(() => {
    const initializeUserDetails = async () => {
      const storedUserDetails = userDetailsFromStore.data();
      if (storedUserDetails && storedUserDetails.userDetail) {
        setUserDetails(storedUserDetails.userDetail);
      } else {
        const userData = userFromStore.data;
        if (userData && userData.id) {
          const fetchedUserDetails = await fetchAndStoreUserDetails(userData.id);
          setUserDetails(fetchedUserDetails.userDetail);
        }
      }
    };

    initializeUserDetails();
  }, [userDetailsFromStore.status, navigate]);*/}


  return (
    <>
      {(isMdUp || open) && (
        <Box className={classes.categories}
        sx={{
          backgroundColor: globalData.mode === 'dark' ? "black": "white",
          
        }}>
          <Box
            className={`${classes.iconsLineParent} ${selectedCategory === 'PersonalInfo' ? classes.selectedCategory : ''}`}
            onClick={() => handleCategoryClick('PersonalInfo', '/profile')}
          >
            <PersonIcon 
            sx={{
              color:globalData.mode === 'dark' ? DarkMode.iconColor: '#8dc8fd',
              '&:focus, &:hover': {
               color: globalData.mode === 'dark' ?  DarkMode.iconColorOnSelection : '#8dc8fd',
              }
              }}
            />
            <Typography variant="body1"
            sx={{
              color:globalData.mode === 'dark' ? DarkMode.titleAndText: "black",
              '&:hover': {
               color: globalData.mode === 'dark' ?  DarkMode.titleAndTextOnSelection : "black",
              }
              }}>
              Personal Info
            </Typography>
          </Box>
          <Box
            className={`${classes.iconsLineParent} ${selectedCategory === 'MyNotes' ? classes.selectedCategory : ''}`}
            onClick={() => handleCategoryClick('MyNotes', '/notes')}
          >
            <NoteAltIcon 
            sx={{
              color:globalData.mode === 'dark' ? DarkMode.iconColor: '#8dc8fd',
              '&:focus, &:hover': {
               color: globalData.mode === 'dark' ?  DarkMode.iconColorOnSelection : '#8dc8fd',
              }
              }}/>
            <Typography variant="body1"
            sx={{
              color:globalData.mode === 'dark' ? DarkMode.titleAndText: "black",
              '&:focus, &:hover': {
               color: globalData.mode === 'dark' ?  DarkMode.titleAndTextOnSelection : "black",
              }
              }}
            >
              My Notes
            </Typography>
          </Box>
          <Box
            className={`${classes.iconsLineParent} ${selectedCategory === 'LoginSecurity' ? classes.selectedCategory : ''}`}
            onClick={() => handleCategoryClick('LoginSecurity', '/LoginAndSecurity')}
          >
            <SecurityIcon 
            sx={{
              color:globalData.mode === 'dark' ? DarkMode.iconColor: '#8dc8fd',
              '&:focus, &:hover': {
               color: globalData.mode === 'dark' ?  DarkMode.iconColorOnSelection : '#8dc8fd',
              }
              }}
            />
            <Typography variant="body1"
            sx={{
              color:globalData.mode === 'dark' ? DarkMode.titleAndText: "black",
              '&:focus, &:hover': {
               color: globalData.mode === 'dark' ?  DarkMode.titleAndTextOnSelection : "black",
              }
              }}>
              Login and Security
            </Typography>
          </Box>
          <Box
            className={`${classes.iconsLineParent} ${selectedCategory === 'Feedback' ? classes.selectedCategory : ''}`}
            onClick={() => handleMenuItemClick('https://forms.gle/NXYkGoCh3uS8hsDq6')}
            // ('https://qurenote.ai/feedback-form/')
          >
            <FeedbackIcon
            sx={{
              color:globalData.mode === 'dark' ? DarkMode.iconColor: '#8dc8fd',
              '&:focus, &:hover': {
               color: globalData.mode === 'dark' ?  DarkMode.iconColorOnSelection : '#8dc8fd',
              }
              }} />
            <Typography variant="body1"
            sx={{
              color:globalData.mode === 'dark' ? DarkMode.titleAndText: "black",
              '&:focus, &:hover': {
               color: globalData.mode === 'dark' ?  DarkMode.titleAndTextOnSelection : "black",
              }
              }}>
              Feedback
            </Typography>
          </Box>
          <Box
            className={`${classes.iconsLineParent} ${selectedCategory === 'UserManual' ? classes.selectedCategory : ''}`}
            onClick={handleMenuClick}
          >
            <MenuIcon 
            sx={{
              color:globalData.mode === 'dark' ? DarkMode.iconColor: '#8dc8fd',
              '&:focus, &:hover': {
               color: globalData.mode === 'dark' ?  DarkMode.iconColorOnSelection : '#8dc8fd',
              }
              }}/>
            <Typography variant="body1"
            sx={{
              color:globalData.mode === 'dark' ? DarkMode.titleAndText: "black",
              '&:focus, &:hover': {
               color: globalData.mode === 'dark' ?  DarkMode.titleAndTextOnSelection : "black",
              }
              }}>
              Documentation
            </Typography>
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleMenuItemClick('https://qurenote.ai/user-guide/')}>User Guide</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('https://qurenote.ai/faq/')}>FAQ</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('https://qurenote.ai/help-center/')}>Help Center</MenuItem>
          </Menu>
        </Box>
      )}
    </>
  );
};

export default CategoryMenu;




