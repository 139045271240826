import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";

interface GlobalData {
  [key: string]: any;
}

interface DataContextType {
  globalData: GlobalData;
  setGlobalData: React.Dispatch<React.SetStateAction<GlobalData>>;
}

export const DataContext = createContext<DataContextType | null>(null);

export const DataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  // Read the mode from localStorage, defaulting to "light" if not found
  const
    storedMode = localStorage.
      getItem
      (
        "themeMode"
      ) ||
      "light"
    ;
  // Initialize globalData with the stored mode
  const
    [globalData, setGlobalData] = useState<
      GlobalData
    >({
      mode
        : storedMode,
    });
  // Update localStorage whenever globalData.mode changes
  useEffect(() => {
    localStorage.setItem("themeMode", globalData.mode);
  }, [globalData.mode]);
  
  return (
    <DataContext.Provider value={{ globalData, setGlobalData }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = (): DataContextType => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useData must be used within a DataProvider");
  }
  return context;
};
