import { configureStore } from '@reduxjs/toolkit';
import userSlice from './user';
import userDetailsSlice from './userDetails';

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    userDetails: userDetailsSlice.reducer,
    // other reducers can go here
  },
});

export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;